import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import image from "../../skin Images/img3.jpeg";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";

function Brands() {
  const [brands, setBrands] = useState([]);
  const [visibleBrands, setVisibleBrands] = useState(8); // Initially display 8 brands

  useEffect(() => {
    async function getBrands() {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getBrands}`
        );
        setBrands(response.data.result);
      } catch (error) {
        console.log("Fetching data is not successful", error);
      }
    }
    getBrands();
  }, []);

  // Function to show more brands
  const handleShowMore = () => {
    setVisibleBrands((prevVisible) => prevVisible + 8); // Show 8 more brands on each click
  };

  return (
    <section className="recent-projects">
      <div className="auto-container">
        <div className="sec-title clearfix">
          <div className="pull-left">
            <h2 style={{ color: "rgba(111, 135, 81, 1)" }}>Brands</h2>
          </div>
        </div>
        <div className="row clearfix">
          {/* Display brands */}
          <article
            className="inner-box wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            {brands.slice(0, visibleBrands).map(({ _id, brandName, imgData }) => (
              <div
                key={_id}
                className="column default-featured-column col-md-3 col-sm-6 col-xs-12"
              >
                <figure className="image-box">
                  <NavLink to={`/brand/${_id}`}>
                    <img
                      src={imgData}
                      style={{ width: "200px", height: "161.203px" }}
                      alt={brandName}
                    />
                  </NavLink>
                </figure>
                <div className="content-box">
                  <h3>
                    <NavLink to={`/${brandName}/${_id}`}>{brandName}</NavLink>
                  </h3>
                </div>
              </div>
            ))}
          </article>
        </div>
        {/* Show More button */}
        {visibleBrands < brands.length && (
          <div className="text-center mt-4">
            <button
              className="btn btn-primary"
              onClick={handleShowMore}
              style={{
                backgroundColor: "rgba(111, 135, 81, 1)",
                border: "none",
                padding: "10px 20px", // Increased padding for larger button size
                fontSize: "15px", // Larger font size
                borderRadius: "5px", // Optional for rounded corners
                marginTop : "20px"
              }}
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

export default Brands;
