import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import image from "../../skin Images/img3.jpeg";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";

function BestSellers({ data }) {
  const [bestSellers, setBestSellers] = useState([]);

  // Fetch best sellers
  useEffect(() => {
    async function getBestSellers() {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.bestSellers}`
        );
        setBestSellers(response.data.resultObject);
      } catch (error) {
        console.log("Fetching data is not successful", error);
      }
    }
    getBestSellers();
  }, []);

  return (
    <section className="recent-projects">
      <div className="auto-container">
        <div className="sec-title clearfix">
          <div className="pull-left">
            <h2 style={{ color: "rgba(111, 135, 81, 1)" }}>Best Sellers</h2>
          </div>
        </div>
        <div className="row clearfix">
          {/*Default Featured Column*/}
          <article
            className="inner-box wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            {bestSellers.slice(0, 8).map(({ productName, imgData, _id, status }) => (
              <div
                className="column default-featured-column col-md-3 col-sm-6 col-xs-12"
                key={_id}
              >
                <figure className="image-box">
                  {status === "Enabled" ? (
                    <NavLink to={`/product/${_id}`}>
                      <img
                        src={imgData}
                        style={{
                          width: "200px",
                          height: "161.203px",
                          opacity: 1,
                        }}
                        alt={productName}
                      />
                    </NavLink>
                  ) : (
                    <NavLink>
                      <img
                        src={imgData}
                        style={{
                          width: "200px",
                          height: "161.203px",
                          opacity: 0.4,
                        }}
                        alt={productName}
                      />
                    </NavLink>
                  )}
                </figure>
                <div className="content-box">
                  {status === "Enabled" ? (
                    <h3>
                      <NavLink to={`/product/${_id}`}>{productName}</NavLink>
                    </h3>
                  ) : (
                    <h3
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginRight: "2rem",
                      }}
                    >
                      Out of stock
                    </h3>
                  )}
                </div>
              </div>
            ))}
          </article>
        </div>
      </div>
    </section>
  );
}

export default BestSellers;
