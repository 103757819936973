import React, { Fragment, useEffect, useState } from "react";
import "../../css/animate.css";
import "../../css/bootstrap.css";
import "../../css/flaticon.css";
import "../../css/jquery.fancybox.css";
import "../../css/hover.css";
import "../../css/font-awesome.css";
import "../../css/owl.css";
import "../../css/responsive.css";
import "../../css/revolution-slider.css";
import { FaInstagram } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoStar } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import "../../css/style.css";
// import img4 from "../../skin Images/img4.jpeg";
import img5 from "../../skin Images/img5.jpeg";
import Desktop1 from "../../skin Images/Desktop1.jpg";
import Desktop2 from "../../skin Images/Desktop2.jpg";

import banner from "../../skin Images/banner.png";
import banner2 from "../../skin Images/banner2.png";
import banner3 from "../../skin Images/banner3.png";
import "./home.css";
import fluidimage2 from "../../images/resource/fluid-image-2.jpg";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import Faq from "../../Components/Layout/Faq";
import BestSellers from "../../Components/Layout/BestSellers";
import Brands from "../../Components/Layout/Brands";
import { Paper, Box, ButtonBase } from "@mui/material";
import { API_ENDPOINTS, BASE_URL } from "../../utils/api";
import { FaShareNodes } from "react-icons/fa6";
import { FaComment } from "react-icons/fa";
import "./home.css";
import { FaShareAlt } from "react-icons/fa";

const slidesData = [
  {
    backgroundImage: `url(${Desktop1})`,
    // backgroundColor: "#6F8751",
  },
  {
    backgroundImage: `url(${Desktop2})`,
    // backgroundColor: "#5E3504",
  },
];
const Home = ({ data }) => {
  const [categories, setCategories] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [bannerStyle, setBannerStyle] = useState({ height: "15vh" });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 750) {
        setBannerStyle({ height: "15vh" });
      } else {
        setBannerStyle({ height: "50vh" });
      }
    };

    // Initial call
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide(
        prevActiveSlide => (prevActiveSlide + 1) % slidesData.length
      );
    }, 4000);
    return () => clearInterval(interval);
  }, []);
  //get all categories
  useEffect(() => {
    async function getCategory() {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getCategories}`
        );

        const data = response.data.result;

        setCategories(data);
      } catch (error) {
        console.log("Fetching data is not successfull", error);
      }
    }
    getCategory();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <section className="main-slider revolution-slider">
          <div className="tp-banner-container">
            <div className="tp-banner">
              <ul>
                <li
                  data-transition="fade"
                  data-slotamount={1}
                  data-masterspeed={1000}
                  data-thumb="images/main-slider/1.jpg"
                  data-saveperformance="off"
                  data-title="Awesome Title Here"
                >
                  {/* <img
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    src={banner}
                    alt
                    // data-bgposition="center top"
                    // data-bgfit="cover"
                    data-bgrepeat="no-repeat"
                  /> */}
                  <div className="section1Div">
                    <Box
                      position="relative"
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {slidesData.map((slide, index) => (
                        <Box
                          key={index}
                          component={Paper}
                          className="banner-images-top"
                          style={{
                            display: activeSlide === index ? "block" : "none",
                            backgroundImage: slide.backgroundImage,
                            // width: "80%",
                            // height: "50vh",

                            backgroundSize: "contain",
                            ...bannerStyle,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            // backgroundColor: slide.backgroundColor,
                          }}
                        ></Box>
                      ))}
                      <Box position="absolute" bottom={0} right={0}>
                        {slidesData.map((_, index) => (
                          <ButtonBase
                            key={index}
                            onClick={() => setActiveSlide(index)}
                            style={{
                              margin: 4,
                              width: 10,
                              height: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                activeSlide === index ? "blue" : "grey",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/*Recent Projects Section*/}
        <section className="recent-projects">
          <div className="auto-container">
            <div className="sec-title clearfix">
              <div className="pull-left">
                <h2 style={{ color: " rgba(111, 135, 81, 1)" }}>
                  Shop By Categories
                </h2>
              </div>
            </div>
            <div className="row clearfix">
              {/*Default Featured Column*/}
              <article
                className="inner-box wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                {categories.map(({ categoryName, _id, imgData }) => (
                  <div className="column default-featured-column col-md-3 col-sm-6 col-xs-12">
                    <React.Fragment key={_id}>
                      <figure className="image-box">
                        <NavLink to={`/${categoryName}/${_id}`}>
                          <img
                            src={imgData}
                            style={{ width: "200px", height: "161.203px" }}
                            alt={""}
                          />
                        </NavLink>
                      </figure>
                      <div className="content-box ">
                        <h3>
                          <NavLink to={`/${categoryName}/${_id}`}>
                            {categoryName}
                          </NavLink>
                        </h3>
                      </div>
                    </React.Fragment>
                  </div>
                ))}
              </article>
            </div>
          </div>
        </section>
        <BestSellers data={data} />
        <Brands data={data} />
        <Faq />
        <div>
          {/* <div className="title-name" >
            <h1 style={{ color: " rgba(111, 135, 81, 1)", fontWeight: 800 }}>
              Welcome to Beauty Barn Rewards Points
            </h1>
          </div>
          <div style={{ display: "flex", justifyContent: "center", color: "#8d8d8d", marginTop: "10px" }}>
            <h4><span style={{ fontWeight: "bolder", color: "black" }}>Join</span> <span style={{ fontWeight: "bolder", color: "black" }}>Shop</span> and <span style={{ fontWeight: "bolder", color: "black" }}>Earn</span> points and avail discounts on your checkout. Here's how.</h4>
          </div> */}


          <div className="rewards-title">
            <h1> Welcome to Noura Rewards</h1>
          </div>
          <div className="rewards-description">
            <h4>
              <span>Get </span> <span>coupons </span>of <span>Noura </span>skincare. Here's how.
            </h4>
          </div>
          {/* <div style={{ display: 'flex', width: "100%", padding: "20px", margin: "2px auto" }} >
            <div style={{ borderRight: "1px dashed gray", width: "20%" }} >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "15px", }}>
                <div style={{ marginBottom: "6px", border: "3px solid black", borderRadius: "50%", padding: "10px", }}><FaUser size="40px" /></div>
                <p style={{ fontSize: "2.0rem", color: "#8d8d8d" }}>Earn 1000 points when you sign up for a new account and use the points when you make your first purchase.</p>
              </div>
            </div>
            <div style={{ borderRight: "1px dashed gray", width: "20%" }}  >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "15px", }}>
                <div style={{ marginBottom: "6px", border: "3px solid black", borderRadius: "50%", padding: "10px", }}><FaShoppingCart size="40px" /></div>
                <p style={{ fontSize: "2.0rem", color: "#8d8d8d" }}>Earn points for every purchase.</p>
              </div>
            </div>
            <div style={{ borderRight: "1px dashed gray", width: "20%" }} >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "15px", }}>
                <div style={{ marginBottom: "6px", border: "3px solid black", borderRadius: "50%", padding: "10px", }}><IoStar size="40px" /></div>
                <p style={{ fontSize: "2.0rem", color: "#8d8d8d" }}>***Each time your review is approved by our team, 100 points will be added to your account.</p>
              </div>
            </div>
            <div style={{ borderRight: "1px dashed gray", width: "20%" }} >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "15px", }}>
                <div style={{ marginBottom: "6px", border: "3px solid black", borderRadius: "50%", padding: "10px", }}><FaComments size="40px" /></div>
                <p style={{ fontSize: "2.0rem", color: "#8d8d8d" }}>***Every time your question / answer to another user's question is approved,100 points will be added to your account.</p>
              </div>
            </div>
            <div style={{ borderRight: "1px dashed gray", width: "20%" }} >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "15px", }}>
                <div style={{ marginBottom: "6px", border: "3px solid black", borderRadius: "50%", padding: "10px", }}><FaShareAlt size="40px" /></div>
                <p style={{ fontSize: "2.0rem", color: "#8d8d8d" }}>Refer a friend and earn 500 points,once they register and complete their purchase.</p>
              </div>
            </div>







          </div> */}

        </div>





        <div className="points-container">
          <div className="points-box signup-box">
            <div className="points-inner">
              <div className="points-icon"><FaInstagram size="40px" /></div>
              <p className="points-description">Follow  our Instagram page
                @nourakoreanskincare.</p>
            </div>
          </div>
          <div className="points-box purchase-box">
            <div className="points-inner">
              <div className="points-icon"><FaShareAlt size="40px" /></div>
              <p className="points-description">Share any instagram post to your story.</p>
            </div>
          </div>
          <div className="points-box review-box">
            <div className="points-inner">
              <div className="points-icon"><FaComments size="40px" /></div>
              <p className="points-description">Comment and tag 3 friends</p>
            </div>

          </div>
          {/* <div className="points-box question-box">
            <div className="points-inner">
              <div className="points-icon"><FaComments size="40px" /></div>
              <p className="points-description">***Every time your question/answer to another user's question is approved, 100 points will be added to your account.</p>
            </div>
          </div> */}
          {/* <div className="points-box referral-box">
            <div className="points-inner">
              <div className="points-icon"><FaShareAlt size="40px" /></div>
              <p className="points-description">Refer a friend and earn 500 points, once they register and complete their purchase.</p>
            </div>
          </div> */}

        </div>
        {/* <div className="btn-container">
          <div className="res-btn">
            <div className="res-btn-text">
            JOIN BEAUTY BARN
            </div>
          </div>
          <div className="res-btn">
            <div className="res-btn-text">
              Start Shopping Now
            </div>
          </div>
        </div> */}


        {/* <div style={{ margin: "40px", display: "flex", gap: "12px", justifyContent: "center" }}>
          <div className="res-btn" style={{
            border: "3.3px solid black", width: "16%", height: "49px", display: "flex", justifyContent: "center", alignItems: "center", padding: "3px"
          }}>
            <div className="res-btn-text" style={{ fontSize: "1.6rem", fontWeight: "bold", margin: "auto" }}>
              JOIN BEAUTY BARN
            </div>
          </div>
          <div className="res-btn" style={{
            border: "3.3px solid black", width: "16%", height: "49px", display: "flex", justifyContent: "center", alignItems: "center", padding: "3px"
          }}>
            <div className="res-btn-text" style={{ fontSize: "1.6rem", fontWeight: "bold", margin: "auto" }}>
              Start Shopping Now
            </div>
          </div>

        </div> */}










        {/* <section className="main-features">
          <div className="auto-container">
            <div className="title-box text-center">
              <h1>Welcome to</h1>
              <h2>Noura</h2>
              <div className="text">
          It's something that's been ingrained in Korean culture for
                millennia.” Unlike the West where cosmetics come in an
                unnaturally sterile shade of white, Korean skincare is known for
                experimenting with more natural ingredients like bee venom,
                snail slime, and even volcanic ash.
              </div>
            </div>
            <div style={{ display: "flex", gap: "30px" }}>
              <div style={{}}>
                <img src={product1} style={{ width: "90%" }} />
              </div>
              <div style={{}}>
                <img src={product2} style={{ width: "90%" }} />
              </div>
              <div style={{}}>
                <img src={product3} style={{ width: "90%" }} />
              </div>
              <div style={{}}>
                <img src={product4} style={{ width: "90%" }} />
              </div>
            </div>
          </div>
        </section> */}
        {/*Featured Fluid Section*/}
        {/* <section className="featured-fluid-section">
          <div className="outer clearfix"> */}
        {/*Image Column*/}
        {/* <div
              className="image-column"
              style={{ backgroundImage: `url(${img5})` }}
            /> */}
        {/* <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItemsContent: "center",
                }}
              >
                Welcome to noura rewards
              </div>
            </div>

            <article
              className="column text-column dark-column wow fadeInRight"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
              style={{ backgroundImage: `url(${fluidimage2})` }}
            > */}
        {/* <div className="content-box pull-left">
                <h2 style={{ fontSize: "2.6rem", fontWeight: "800" }}>
                  WELCOME Noura !
                </h2>
                <div className="title-text">
                  Noura is a premium Korean skincare brand dedicated to bringing
                  the secrets of K-beauty to the world. Founded in the heart of
                  Seoul, we blend traditional Korean beauty wisdom with
                  cutting-edge scientific advancements to create products that
                  deliver visible and lasting results.
                </div>
                <Link
                  className="theme-btn"
                  style={{
                    backgroundColor: " rgb(111, 135, 81)",
                    borderColor: "rgb(111, 135, 81)",
                    letterSpacing: "2px",
                    padding: "15px 15px",
                    color: "white",
                    borderRadius: "3px",
                    fontWeight: "500",
                  }}
                >
                  Read More
                </Link>
              </div> */}
        {/* <div className="clearfix" /> */}
        {/* </article> */}
        {/* </div> */}
        {/* </section> */}
        {/* <div className="bottom-container">
          <div className="left-portion">
            <div className="title-bottom">Welcome to noura rewards</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="component-bottom">
                <div>
                  <FaInstagram size={120} className="icon-bottom" />
                </div>
                <div className="subtitle-bottom">Follow</div>
                <div
                  // style={{ fontSize: "20px", fontWeight: "800" }}
                  className="content-bottom"
                  style={{ width: "80%" }}
                >
                  Follow our Insta page
                </div>
              </div>
              <div className="component-bottom">
                <div>
                  <FaShareNodes size={120} className="icon-bottom" />
                </div>
                <div className="subtitle-bottom">Share</div>
                <div style={{ width: "80%" }} className="content-bottom">
                  Share this post to your story
                </div>
              </div>
              <div className="component-bottom" style={{ marginRight: "5px" }}>
                <div>
                  <FaComment size={120} className="icon-bottom" />
                </div>
                <div className="subtitle-bottom">Comment</div>
                <div
                  // style={{ fontSize: "20px", fontWeight: "800" }}
                  style={{ width: "80%" }}
                  className="content-bottom"
                >
                  Comment and tag 3 of your friends
                </div>
              </div>
            </div>
          </div>

          <div
            className="right-portion"
            style={{ backgroundImage: `url(${fluidimage2})` }}
          >
            <div className="fluid-section-bottom"></div>
            <div className="content-box pull-left box-bottom">
              <h2
                style={{
                  fontSize: "2.8rem",
                  fontWeight: "800",
                  zIndex: "101",
                  color: "white",
                  lineHeight: "1.5em",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                  letterSpacing: "2px",
                }}
                className="head-bottom"
              >
                WELCOME Noura !
              </h2>
              <div className="title-text-bottom">
                Noura is a premium Korean skincare brand dedicated to bringing
                the secrets of K-beauty to the world. Founded in the heart of
                Seoul, we blend traditional Korean beauty wisdom with
                cutting-edge scientific advancements to create products that
                deliver visible and lasting results.
              </div>
              <Link
                className="theme-btn btnBottom"
                style={{
                  backgroundColor: " rgb(111, 135, 81)",
                  borderColor: "rgb(111, 135, 81)",
                  letterSpacing: "2px",
                  padding: "15px 15px",
                  color: "white",
                  borderRadius: "3px",
                  fontWeight: "500",
                  position: "relative",
                  zIndex: "101",
                }}
              >
                Read More
              </Link>
            </div>
          </div>
        </div> */}


        {/*Two Column Fluid */}
        {/*<section class="two-column-fluid">

      
    	
  
    	<div class="outer clearfix">
  	
      
      <article class="column left-column" style="background-image:url(images/resource/fluid-image-3.jpg);">
          
          <div class="content-box pull-right">	
              <h2>Some <span class="normal-font theme_color">Facts</span></h2>
              <div class="title-text">Lorem ipsum dolor some link sit amet, cum at inani interesset</div>
              <div class="text">We’re extremely proud of what we’ve achieved together with charitie lorem individuals, philanthropists and schools since the Big Give was founded in 2007, and here are some fact from our achivemnet.</div>
              <br>
              
              <div class="clearfix">
              	<div class="icon-box">
                  	<div class="icon"><span class="flaticon-shapes-1"></span></div>
                      <div class="lower-box">
                      	<h4>$<span class="count-text" data-stop="7845910" data-speed="1500">7,845,910</span></h4>
                          <span class="title">Raised</span>
                      </div>
                  </div>
                  
                  <div class="icon-box">
                  	<div class="icon"><span class="flaticon-tool-4"></span></div>
                      <div class="lower-box">
                      	<h4>$<span class="count-text" data-stop="13360" data-speed="1500">12,360</span></h4>
                          <span class="title">Projects</span>
                      </div>
                  </div>
                  
                  <div class="icon-box">
                  	<div class="icon"><span class="flaticon-favorite"></span></div>
                      <div class="lower-box">
                      	<h4>$<span class="count-text" data-stop="78459" data-speed="1500">225,580</span></h4>
                          <span class="title">Donations</span>
                      </div>
                  </div>
              </div>
          </div>
          
          <div class="clearfix"></div>
      </article>
      
      <article class="column right-column" style="background-image:url(images/resource/fluid-image-4.jpg);">
          
          <div class="content-box pull-left">	
          	<div class="outer-box">
              	<div class="quote-icon"><span class="fa fa-quote-left"></span></div>
                  <h2>word from <span class="normal-font">CEO</span></h2>
                  
                  <div class="text-content">
                      <div class="text"><p>How to pursue pleasure rationally  consequences that are extremeely painful or again is there anyones who loves or  pursues or desires to obtain pain of itself because its sed great pleasure get well soon.</p></div>
                      <div class="information clearfix">
                          <div class="info">
                              <figure class="image-thumb"><img src="images/resource/ceo-thumb.jpg" alt=""></figure>
                              <h3>Alex Zender</h3>
                              <p>CEO of Go Green</p>
                          </div>
                          <div class="signature"><img src="images/resource/signature-image-1.png" alt=""></div>
                      </div>
                  </div>
                  
              </div>
          </div>
          
          <div class="clearfix"></div>
      </article>
      
  </div>
  
    </section>*/}
        {/*Events Section*/}
        {/*<section class="events-section latest-events">
    	<div class="auto-container">
  	
      <div class="sec-title text-center">
          <h2>Latest <span class="normal-font theme_color">Event</span></h2>
          <div class="text">Lorem ipsum dolor sit amet, cum at inani interes setnisl omnium dolor amet amet qco modo cum text </div>
      </div>
  	<div class="row clearfix">
          
          <div class="column default-featured-column style-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
          	<article class="inner-box">
          		<figure class="image-box">
                  	<a href="#"><img src="images/resource/featured-image-5.jpg" alt=""></a>
                      <div class="post-tag">Featured</div>
                  </figure>
                  <div class="content-box">
                  	<h3><a href="#">One Tree Thousand Hope</a></h3>
                      <div class="column-info">13-14 Feb in Sanfransico, CA</div>
                      <div class="text">Lorem ipsum dolor sit amet, eu qui modo expeten dis reformidans, ex sit appetere sententiae.. </div>
                      <a href="#" class="theme-btn btn-style-three">Read More</a>
                  </div>
              </article>
          </div>
          
          <div class="column default-featured-column style-two col-lg-4 col-md-6 col-sm-6 col-xs-12">
          	<article class="inner-box">
          		<figure class="image-box">
                  	<a href="#"><img src="images/resource/featured-image-6.jpg" alt=""></a>
                  </figure>
                  <div class="content-box">
                  	<h3><a href="#">One Tree Thousand Hope</a></h3>
                      <div class="column-info">13-14 Feb in Sanfransico, CA</div>
                      <div class="text">Lorem ipsum dolor sit amet, eu qui modo expeten dis reformidans, ex sit appetere sententiae.. </div>
                      <a href="#" class="theme-btn btn-style-three">Read More</a>
                  </div>
              </article>
          </div>
          
          <div class="column default-featured-column links-column col-lg-4 col-md-6 col-sm-6 col-xs-12">
          	<article class="inner-box">
          		<div class="vertical-links-outer">
                  	<div class="link-block">
                      	<div class="default inner"><figure class="image-thumb"><img src="images/resource/post-thumb-1.jpg" alt=""></figure><strong>Togather we can change the</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></div>
                          <a href="#" class="alternate"><strong>Togather we can change the</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></a>
                      </div>
                      
                      <div class="link-block">
                      	<div class="default inner"><figure class="image-thumb"><img src="images/resource/post-thumb-2.jpg" alt=""></figure><strong>Urgent Clothe Needed</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></div>
                         <a href="#" class="alternate"><strong>Urgent Clothe Needed</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></a>
                      </div>
                      
                      <div class="link-block">
                      	<div class="default inner"><figure class="image-thumb"><img src="images/resource/post-thumb-3.jpg" alt=""></figure><strong>Let’s plant 200 tree each...</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></div>
                          <a href="#" class="alternate"><strong>Let’s plant 200 tree each...</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></a>
                      </div>
                      
                      <div class="link-block">
                      	<div class="default inner"><figure class="image-thumb"><img src="images/resource/post-thumb-1.jpg" alt=""></figure><strong>Keep your house envirome..</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></div>
                          <a href="#" class="alternate"><strong>Keep your house envirome..</strong><span class="desc">Lorem ipsum dolor sit amet et siu amet sio audiam si copiosaei mei purto </span></a>
                      </div>
                      
                  </div>
              </article>
          </div>
          
          
      </div>
  </div>
    </section>*/}
        {/*Testimonials*/}
        {/*<section class="testimonials-section" style="background-image:url(images/background/testimonials-bg.jpg);">
		<div class="auto-container">
      
      <div class="sec-title text-center">
          <h2>Testi<span class="normal-font theme_color">Monials</span></h2>
          <div class="text">Lorem ipsum dolor sit amet, cum at inani interes setnisl omnium dolor amet amet qco modo cum text </div>
      </div>
           
  	<div class="testimonials-slider testimonials-carousel">
      	
          <article class="slide-item">
          	
              <div class="info-box">
              	<figure class="image-box"><img src="images/resource/testi-image-1.jpg" alt=""></figure>
              	<h3>Mark Pine</h3>
                  <p class="designation">Rome, Italy</p>
              </div>
              
              <div class="slide-text">
                  <p>“But I must explain to you the how all this mistaken idea of thealorem qco denouncing pleasure”</p>
              </div>
          </article>
          
          <article class="slide-item">
          	
              <div class="info-box">
              	<figure class="image-box"><img src="images/resource/testi-image-2.jpg" alt=""></figure>
              	<h3>Mark Pine</h3>
                  <p class="designation">Rome, Italy</p>
              </div>
              
              <div class="slide-text">
                  <p>“But I must explain to you the how all this mistaken idea of thealorem qco denouncing pleasure”</p>
              </div>
          </article>
          
          <article class="slide-item">
          	
              <div class="info-box">
              	<figure class="image-box"><img src="images/resource/testi-image-3.jpg" alt=""></figure>
              	<h3>Mark Pine</h3>
                  <p class="designation">Rome, Italy</p>
              </div>
              
              <div class="slide-text">
                  <p>“But I must explain to you the how all this mistaken idea of thealorem qco denouncing pleasure”</p>
              </div>
          </article>
          
          
      </div>
      
  </div>    
    </section>*/}
        <div style={{ fontWeight: "bold", fontSize: "1.6rem", marginLeft: "100px", width: "50%", margin: "10px auto", alignItems: 'center', }}>

          <div >1.Follow steps & send screenshot in Instagram or what's app
          </div>
          <div>2.Get coupons of Noura skincare goodies and indulge in the ultimate self-care experience.
            Don't miss out.</div>
        </div>

      </div >
      {/*End pagewrapper*/}
      {/*Scroll to top*/}
      <div
        className="scroll-to-top scroll-to-target"
        data-target=".main-header"
      >
        <span className="fa fa-long-arrow-up" />
      </div>
    </div >
  );
};

export default Home;
