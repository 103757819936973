import { Menu, Typography, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function MegaMenu() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const onMenuItemClick = (item) => {
    setSelectedKeys([item.key]); // Update the selected keys
    navigate(item.key); // Navigate to the route associated with the key
    // Force a page reload to see if it resolves the issue
    window.location.href = item.key;
  };

  useEffect(() => {
    const pathKey = location.pathname.split("/").pop(); // Get the last part of the URL
    setSelectedKeys([pathKey]); // Update selected key based on current path
  }, [location.pathname]); // Runs when location.pathname changes

  return (
    <div
      style={{ backgroundColor: "white", padding: "20px 10px" }}
      key={location.pathname} // Add key prop here to force re-render
    >
      <Typography.Title level={3} style={{ textAlign: "center" }}>
        Products List
      </Typography.Title>
      <Row justify="space-around" align="top">
        <Col xs={24} sm={12} lg={6}>
          <Menu
            key={location.pathname} // Add key here to ensure re-render
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              {
                label: "All Products",
                key: "#", // Navigate to the homepage
                type: "group",
              },
              {
                label: "All Exfoliators",
                key: "/Exfoliators/67551ad6b1154f4973e0d204", // Matches the route
              },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Menu
            key={location.pathname} // Add key here to ensure re-render
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              { label: "Skin Care", key: "SkinCare", type: "group" },
              {
                label: "Toners",
                key: "/Toner/6755318e7894ff43decb0e1f",
              },
              {
                label: "Moisturizers",
                key: "/Moisturizers/675517d4b1154f4973e0d1d6",
              },
              {
                label: "Sunscreen",
                key: "/Sunscreen/6755311d7894ff43decb0e0a",
              },
              {
                label: "Lipcare",
                key: "/Lipcare/67551846b1154f4973e0d1e7",
              },
              {
                label: "Facewash",
                key: "/Facewash/67551979b1154f4973e0d1f8",
              },
              {
                label: "Serums",
                key: "/Serum/6755327a7894ff43decb0e2c",
              },
              {
                label: "Exfoliators",
                key: "/Exfoliators/67551ad6b1154f4973e0d204",
              },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Menu
            key={location.pathname} // Add key here to ensure re-render
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              { label: "Haircare", key: "Haircare", type: "group" },
              {
                label: "Hair Care",
                key: "/Haircare/675530da7894ff43decb0dfc",
              },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Menu
            key={location.pathname} // Add key here to ensure re-render
            onClick={onMenuItemClick}
            selectedKeys={selectedKeys}
            mode="inline"
            items={[
              { label: "Skin type", key: "SkinType", type: "group" },
              {
                label: "How to check your skin type?",
                key: "/skintype",
              },
              {
                label: "To know more about skin",
                key: "/skintype2",
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}

export default MegaMenu;
